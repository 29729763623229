
import Vue from 'vue';
import { Contact, Contacts } from '@point/utility-classes';

export default Vue.extend({
  name: 'gaActions',
  components: {
    GaEmailContent: () => import('./gaEmailContentNew.vue'),
  },
  props: ['advertiser', 'gaConnectors', 'editRights', 'action', 'gaAccountConnected'],
  data: (): {
    showSendEmailForm: boolean;
  } => ({
    showSendEmailForm: false,
  }),
  mounted() {
    if (this.action === 'email') {
      this.sendEmail();
    }
  },
  methods: {
    sendEmail(): void {
      this.showSendEmailForm = true;
    },
    async removeGAToken(): Promise<void> {
      await this.$store.dispatch('linearAdmin/deleteGATokenDataApi', { id: this.advertiser.id });
      window.location.reload();
    },
  },
  computed: {
    gaStatus(): string {
      // site does not have GA or has blocked our crawler
      if (this.advertiser.WURL && (!this.advertiser.GASite || this.advertiser.GASite === 'blocked')) return 'missingGA';

      // not connected
      if (this.advertiser.GASite === true && this.advertiser.GoogleAnalyticsRequestStatus?.toLowerCase() !== 'received')
        return 'notConnected';
      return '';
    },
    gaContactStatus(): string {
      // if we have a GA ready site, but we're not connected and we don't have a technical contact
      if (
        !this.advertiser.GaHealthy &&
        !this.technicalContact?.is_copy_of_dm &&
        (!this.technicalContact || !this.technicalContact.email)
      )
        return 'missingTechnicalContact';

      // missing media contact (MC)
      if (this.advertiser.GASite === true && !this.advertiser.Connected && !this.mediaContact.email)
        return 'missingMediaContact';
      return '';
    },
    resendEmail(): boolean {
      if (!this.advertiser.GASite) return false;
      if (this.gaConnectors?.RequestScope) return true;
      if (this.gaConnectors && !this.gaConnectors?.Healthy) return true;
      else if (!this.gaConnectors && this.advertiser?.GoogleAnalyticsRequestStatus?.toLowerCase() === 'received')
        return true;
      return false;
    },
    showEmailDialog(): boolean {
      if (this.advertiser && this.mediaContact && this.technicalContact) return true;
      return false;
    },
    ccEmailAddress(): string {
      // if your logged in email address is different from the Media Contact, use it
      if (this.$store.state.customer.user.email && this.$store.state.customer.user.email !== this.mediaContact.email)
        return this.$store.state.customer.user.email;
      return '';
    },
    contacts(): Contacts {
      return this.$store.state?.advertiser?.contacts || null;
    },
    mediaContact(): Contact {
      return this.contacts?.media_company;
    },
    technicalContact(): Contact {
      const techContact = this.contacts?.technical;
      if (techContact?.is_copy_of_dm) {
        // return the decision maker
        return this.contacts?.decision_maker;
      } else return techContact;
    },
    removeToken(): boolean {
      return this.gaAccountConnected && this.editRights.hasFullEdit;
    },
  },
});
